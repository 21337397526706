import React, { Component } from 'react';
import axios from 'axios';
import { Redirect, Link } from 'react-router-dom';
import './donationthanks.css';

import Thanks from '../../images/krembo-thanks2.png';
import Logo from '../../images/navbar/logo.svg';
import Navbar from '../Navbar/Navbar';

class DonationThanks extends Component {
    constructor() {
        super();

        this.state = {
            redirected: false,
            showIgul: false,
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        if (true) {
            const navbar = document.querySelector(
                'section[class="navbar__wrapper"]',
            );
            const footer = document.querySelector(
                'section[class="footer__wrapper"]',
            );
            // if (navbar) navbar.remove();
            if (footer) footer.remove();
        }
        const url = document.URL.split('?');
        if (url[1]) {
            const parameters = Object.fromEntries(
                new URLSearchParams(window.location.search),
            );
            if (parameters && parameters.cardMask) {
                const {
                    cardMask,
                    uniqueID,
                    personalId,
                    salesForceId,
                    txId,
                    mid,
                } = parameters;
                let sent = sessionStorage.getItem(`sent-${salesForceId}`);

                console.log(
                    '🚀 ~ DonationThanks ~ componentDidMount ~ sent:',
                    sent,
                );
                if (sent !== 'true') {
                    sessionStorage.setItem(`sent-${salesForceId}`, 'true');

                    const body = {
                        cardMask,
                        uniqueID,
                        personalId,
                    };
                    axios.post(
                        'https://reg-api.krembo.org.il/did-roundup',
                        body,
                    );
                    axios
                        .get(
                            `https://api.krembo.org.il/donationsuccess/${uniqueID}?personalId=${personalId}&salesForceId=${salesForceId}&txId=${txId}&mid=${mid}`,
                        )
                        .then((res) => {
                            console.log('🚀 ~ getting  :', res.data);
                        });
                }
            }
        } else {
            this.setState({ redirected: true });
        }
        // Meta Pixel JavaScript code
        const metaPixelScript = document.createElement('script');
        metaPixelScript.id = 'test';
        metaPixelScript.innerHTML = `
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '622499939042041');
    fbq('track', 'PageView');
    fbq('track', 'Purchase'); 
  `;
        document.head.appendChild(metaPixelScript);

        // Meta Pixel noscript image
        const noscriptImg = document.createElement('noscript');
        noscriptImg.innerHTML = `
    <img height="1" width="1" style="display:none"
    src="https://www.facebook.com/tr?id=914305182371932&ev=PageView&noscript=1"
    />
  `;
        document.body.appendChild(noscriptImg);

        setTimeout(() => {
            this.setState({ showIgul: true });
        }, 2000);
    }

    render() {
        const { redirected, showIgul } = this.state;

        return (
            <div className="donation-thanks">
                {redirected ? <Redirect to="/" /> : null}
                <div className="thanks-image"></div>
                {/* <img src={Thanks} alt="krembo-thanks-message" /> */}
                <h1 className="donation-thanks-text">
                    עמותת 'כנפיים של קרמבו'<br></br> מודה לך על תרומתך{' '}
                </h1>
                <p class="donation-under-text">
                    קבלה תישלח בדוא"ל עד שני ימי עסקים מיום ביצוע התרומה
                </p>
                <a href="https://krembo.org.il">
                    <button>
                        <p>{this.props.lang.BTN}</p>
                    </button>
                </a>
                {showIgul && (
                    <div className="igul-popup">
                        <div
                            className="close"
                            onClick={() => {
                                this.setState({ showIgul: false });
                            }}
                        >
                            X
                        </div>
                        <h2>
                            האם תרצו לעזור לנו <br></br>ולעגל את האגורות לטובה?
                        </h2>
                        <p>
                            בכל רכישה בכרטיס האשראי שלך
                            <br></br>
                            יעוגל סכום הקנייה לשקל הקרוב ואותן
                            <br></br>
                            אגורות יתרמו ישירות לילדי 'כנפיים של קרמבו'.
                            <br></br> לדוגמא: קנית ב9.90 ₪? תרמת 10 אגורות.
                        </p>
                        <a
                            href="https://krembo.org.il/round-for-good/"
                            target="_blank"
                            dir="rtl"
                        >
                            אני רוצה לעגל לטובה &gt;&gt;
                        </a>
                        <div
                            className="bottom-close"
                            onClick={() => {
                                this.setState({ showIgul: false });
                            }}
                        >
                            לא מעוניין תודה
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default DonationThanks;
